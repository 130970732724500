<template>
    <div :class="$route.name=='Validation' ? 'panel-validation':''" class="panel-container">
        <Sidebar v-if="$route.name !== 'Validation'"/>
        <router-view />
    </div>
</template>

<script>
import Sidebar from '../PublicComponents/Sidebar.vue';
    export default {
        name:'Panel',
        components:{
            Sidebar,
        }
    }   
</script>

<style lang="scss" scoped>
.panel-validation{
    padding: 90px 120px !important;
}
.panel-container{
    padding: 90px 200px 90px 50px;
    display: flex;
    flex-direction: row;
    height: 100%;
    min-height: 100vh;
    background: var(--white);
}
@media only screen and(max-width:600px){
    .panel-container{
    padding: 90px 90px 90px 20px;
    }
    .panel-validation{
    padding: 90px 10px !important;
    }
}
</style>