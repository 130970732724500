<template>
    <div class="sidebar-container">
        <div class="items-container">
            <div @click="$router.push('/dashboard')" class="item">
                <inline-svg :class="{'svgitem-active':$route.name=='Dashboard'}"  class="item-name" :src="require('../../assets/Icons/dashboard.svg')" />
                <span :class="{'item-active':$route.name=='Dashboard'}" class="item-name"> داشبورد </span>
            </div>
            <div @click="$router.push({name:'Wallet'})" class="item">
                <inline-svg :class="{'svgitem-active':$route.name=='Wallet'}"  class="item-name" :src="require('../../assets/Icons/wallet.svg')" />
                <span :class="{'item-active':$route.name=='Wallet'}" class="item-name"> کیف پول </span>
            </div>
            <div @click="$router.push({name:'History'})" class="item">
                <inline-svg :class="{'svgitem-active':$route.name=='History'}"  class="item-name" :src="require('../../assets/Icons/history.svg')" />
                <span :class="{'item-active':$route.name=='History'}" class="item-name"> تاریخچه </span>
            </div>
            <div @click="$router.push({name:'Account'})" class="item">
                <inline-svg :class="{'svgitem-active':$route.name=='Account'}"  class="item-name" :src="require('../../assets/Icons/profileIcon.svg')" />
                <span :class="{'item-active':$route.name=='Account'}" class="item-name"> پروفایل </span>
            </div>
            <div @click="$router.push({name:'Setting'})" class="item">
                <inline-svg :class="{'svgitem-active':$route.name=='Setting'}"  class="item-name" :src="require('../../assets/Icons/settings.svg')" />
                <span :class="{'item-active':$route.name=='Setting'}" class="item-name"> تنظیمات </span>
            </div>
            <div @click="$router.push({name:'InviteFriends'})" class="item">
                <inline-svg :class="{'svgitem-active':$route.name=='InviteFriends'}"  class="item-name" :src="require('../../assets/Icons/invite.svg')" />
                <span :class="{'item-active':$route.name=='InviteFriends'}" class="item-name"> دعوت </span>
            </div>
            <div @click="$logout" class="item">
                <inline-svg  class="item-name" :src="require('../../assets/Icons/exit.svg')" />
                <span class="exit item-name"> خروج </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.exit{
    color: var(--red ) !important;
}


.items-container{
    display: flex;
    flex-direction: column;
    row-gap: 50px;
    justify-content: space-between;
    width: 100%;
    .item{
        cursor: pointer;
        display: flex;
        flex-direction: row;
        column-gap: 12px;
        padding: 4px;
        opacity: 1;
        transition: 300ms;
        svg{
            padding: 4px;
            border-radius: 4px;
        }
        &:hover{
            opacity: 1;
        }
        .item-name{
            color: var(--gray-light);
            fill: var(--gray-light) !important;
            font-weight: 500;
            font-size: clamp(12px,1vw,14px);
        }
        .item-active{
            color: var(--darker-primary);
        }
        .svgitem-active{
            fill:white !important;
            background: var(--primary);
        }
    }
}
.sidebar-container{
    position: fixed;
    right: 0;
    top: 0;
    display: flex;
    background: #F0F0F5;
    box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
    padding: 100px 36px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    overflow: scroll;
}
@media only screen and(max-width:600px){
    .item span{
        display: none;
    }
}
@media only screen and(max-width:1000px){
    .sidebar-container{
        padding: 20px;
        padding-top: 80px !important;
    }
}
</style>